@import "~antd/dist/antd.css";

.App {
  /* text-align: center; */
  background-color: #f8f8f8;
  height: 100%;
}

body {
  background-color: #f8f8f8;
}

.metricsGraphicsCon {
  display: inline-block;
}
